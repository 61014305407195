// initialization snippet from koala. loading the koala js is disabled so we can
// do it at the right time in nuxt's lifecycle

/* eslint-disable */
export function initKoala(t) {
  if (window.ko) return
  ;(window.ko = []),
    [
      "identify",
      "track",
      "removeListeners",
      "open",
      "on",
      "off",
      "qualify",
      "ready",
    ].forEach(function (t) {
      ko[t] = function () {
        var n = [].slice.call(arguments)
        return n.unshift(t), ko.push(n), ko
      }
    })
  // NOTE: we handle this injection in plugins/koala.client.ts
  //
  // var n = document.createElement("script")
  // ;(n.async = !0),
  //   n.setAttribute(
  //     "src",
  //     "https://cdn.getkoala.com/v1/pk_.../sdk.js",
  //   ),
  //   (document.body || document.head).appendChild(n)
}
/* eslint-enable */
