import { initKoala } from "@/common/third_party/koala"

export default defineNuxtPlugin({
  name: "koala",
  async setup(nuxtApp) {
    const config = useRuntimeConfig()

    const loadKoala = () => {
      initKoala() // run js that populates window.ko before script is loaded
      const script = document.createElement("script")
      const src = `https://cdn.getkoala.com/v1/${config.public.koalaPublicKey}/sdk.js`
      script.async = !0
      script.setAttribute("src", src)
      document.head.appendChild(script)
    }

    // init koala after page has loaded
    if (config.public.koalaPublicKey) {
      nuxtApp.hook("app:mounted", () => {
        setTimeout(loadKoala, 1)
      })
    }
  },
  parallel: true,
  enforce: "post",
})
