import { default as account_45linkv2Hy9ULsaZMeta } from "/opt/build/repo/pages/account-link.vue?macro=true";
import { default as indexQHLGOCDfydMeta } from "/opt/build/repo/pages/account/index.vue?macro=true";
import { default as _91id_93tOzu0lzA06Meta } from "/opt/build/repo/pages/destinations/[id].vue?macro=true";
import { default as axiomn4Iq6EGDgIMeta } from "/opt/build/repo/pages/destinations/add/axiom.vue?macro=true";
import { default as blackhole4HEHwjUVSKMeta } from "/opt/build/repo/pages/destinations/add/blackhole.vue?macro=true";
import { default as checklyafKAwP6CgrMeta } from "/opt/build/repo/pages/destinations/add/checkly.vue?macro=true";
import { default as datadoggjByAMlY4gMeta } from "/opt/build/repo/pages/destinations/add/datadog.vue?macro=true";
import { default as honeycombIVibO8P8Z5Meta } from "/opt/build/repo/pages/destinations/add/honeycomb.vue?macro=true";
import { default as httpaVI4m1HA8pMeta } from "/opt/build/repo/pages/destinations/add/http.vue?macro=true";
import { default as index17JiYJIofAMeta } from "/opt/build/repo/pages/destinations/add/index.vue?macro=true";
import { default as logscalewnRueJkWZmMeta } from "/opt/build/repo/pages/destinations/add/logscale.vue?macro=true";
import { default as open_telemetryl7NAEawDyBMeta } from "/opt/build/repo/pages/destinations/add/open_telemetry.vue?macro=true";
import { default as posthogQrmxCDTU6DMeta } from "/opt/build/repo/pages/destinations/add/posthog.vue?macro=true";
import { default as prometheus_remote_write17DjfQyUD9Meta } from "/opt/build/repo/pages/destinations/add/prometheus_remote_write.vue?macro=true";
import { default as s3Hx0n6p3fXHMeta } from "/opt/build/repo/pages/destinations/add/s3.vue?macro=true";
import { default as indexP6Kry3Qj9tMeta } from "/opt/build/repo/pages/destinations/index.vue?macro=true";
import { default as forgot_45password6Wrw3WyRqiMeta } from "/opt/build/repo/pages/forgot-password.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as invite_45expiredPjonPJ6YQsMeta } from "/opt/build/repo/pages/invite-expired.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as errormbQEcy1ULKMeta } from "/opt/build/repo/pages/misc/error.vue?macro=true";
import { default as reset_45accountO9tSKBqkF5Meta } from "/opt/build/repo/pages/reset-account.vue?macro=true";
import { default as flagsZt7a34Hb9mMeta } from "/opt/build/repo/pages/settings/flags.vue?macro=true";
import { default as indexoWr0YesFbSMeta } from "/opt/build/repo/pages/settings/index.vue?macro=true";
import { default as _91id_93lhIsMJH14zMeta } from "/opt/build/repo/pages/sources/[id].vue?macro=true";
import { default as cloudtrailIt2N2mJp2bMeta } from "/opt/build/repo/pages/sources/add/cloudtrail.vue?macro=true";
import { default as datadog_agentsUu1xsjq8eMeta } from "/opt/build/repo/pages/sources/add/datadog_agent.vue?macro=true";
import { default as elasticB0qvAOeBGUMeta } from "/opt/build/repo/pages/sources/add/elastic.vue?macro=true";
import { default as heciB1aB1irqtMeta } from "/opt/build/repo/pages/sources/add/hec.vue?macro=true";
import { default as httpMsTQXSJF34Meta } from "/opt/build/repo/pages/sources/add/http.vue?macro=true";
import { default as indexIhkpbbcDDDMeta } from "/opt/build/repo/pages/sources/add/index.vue?macro=true";
import { default as netlifycoP3iwbqS9Meta } from "/opt/build/repo/pages/sources/add/netlify.vue?macro=true";
import { default as open_telemetrydzktkzYYX8Meta } from "/opt/build/repo/pages/sources/add/open_telemetry.vue?macro=true";
import { default as prometheus_remote_write4USrsnNPIcMeta } from "/opt/build/repo/pages/sources/add/prometheus_remote_write.vue?macro=true";
import { default as vercelcfaSenoq47Meta } from "/opt/build/repo/pages/sources/add/vercel.vue?macro=true";
import { default as indexhGqGUpS8VdMeta } from "/opt/build/repo/pages/sources/index.vue?macro=true";
import { default as _91id_93jE5PmwwYF0Meta } from "/opt/build/repo/pages/streams/[id].vue?macro=true";
import { default as indexq1qORXHu9PMeta } from "/opt/build/repo/pages/streams/add/index.vue?macro=true";
import { default as indexyhW7FDF7t2Meta } from "/opt/build/repo/pages/streams/index.vue?macro=true";
export default [
  {
    name: "account-link",
    path: "/account-link",
    meta: account_45linkv2Hy9ULsaZMeta || {},
    component: () => import("/opt/build/repo/pages/account-link.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/opt/build/repo/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "destinations-id",
    path: "/destinations/:id()",
    component: () => import("/opt/build/repo/pages/destinations/[id].vue").then(m => m.default || m)
  },
  {
    name: "destinations-add-axiom",
    path: "/destinations/add/axiom",
    component: () => import("/opt/build/repo/pages/destinations/add/axiom.vue").then(m => m.default || m)
  },
  {
    name: "destinations-add-blackhole",
    path: "/destinations/add/blackhole",
    component: () => import("/opt/build/repo/pages/destinations/add/blackhole.vue").then(m => m.default || m)
  },
  {
    name: "destinations-add-checkly",
    path: "/destinations/add/checkly",
    component: () => import("/opt/build/repo/pages/destinations/add/checkly.vue").then(m => m.default || m)
  },
  {
    name: "destinations-add-datadog",
    path: "/destinations/add/datadog",
    component: () => import("/opt/build/repo/pages/destinations/add/datadog.vue").then(m => m.default || m)
  },
  {
    name: "destinations-add-honeycomb",
    path: "/destinations/add/honeycomb",
    component: () => import("/opt/build/repo/pages/destinations/add/honeycomb.vue").then(m => m.default || m)
  },
  {
    name: "destinations-add-http",
    path: "/destinations/add/http",
    component: () => import("/opt/build/repo/pages/destinations/add/http.vue").then(m => m.default || m)
  },
  {
    name: "destinations-add",
    path: "/destinations/add",
    component: () => import("/opt/build/repo/pages/destinations/add/index.vue").then(m => m.default || m)
  },
  {
    name: "destinations-add-logscale",
    path: "/destinations/add/logscale",
    component: () => import("/opt/build/repo/pages/destinations/add/logscale.vue").then(m => m.default || m)
  },
  {
    name: "destinations-add-open_telemetry",
    path: "/destinations/add/open_telemetry",
    component: () => import("/opt/build/repo/pages/destinations/add/open_telemetry.vue").then(m => m.default || m)
  },
  {
    name: "destinations-add-posthog",
    path: "/destinations/add/posthog",
    component: () => import("/opt/build/repo/pages/destinations/add/posthog.vue").then(m => m.default || m)
  },
  {
    name: "destinations-add-prometheus_remote_write",
    path: "/destinations/add/prometheus_remote_write",
    component: () => import("/opt/build/repo/pages/destinations/add/prometheus_remote_write.vue").then(m => m.default || m)
  },
  {
    name: "destinations-add-s3",
    path: "/destinations/add/s3",
    component: () => import("/opt/build/repo/pages/destinations/add/s3.vue").then(m => m.default || m)
  },
  {
    name: "destinations",
    path: "/destinations",
    component: () => import("/opt/build/repo/pages/destinations/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password6Wrw3WyRqiMeta || {},
    component: () => import("/opt/build/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite-expired",
    path: "/invite-expired",
    meta: invite_45expiredPjonPJ6YQsMeta || {},
    component: () => import("/opt/build/repo/pages/invite-expired.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginBXD9sq3T3sMeta || {},
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "misc-error",
    path: "/misc/error",
    component: () => import("/opt/build/repo/pages/misc/error.vue").then(m => m.default || m)
  },
  {
    name: "reset-account",
    path: "/reset-account",
    component: () => import("/opt/build/repo/pages/reset-account.vue").then(m => m.default || m)
  },
  {
    name: "settings-flags",
    path: "/settings/flags",
    component: () => import("/opt/build/repo/pages/settings/flags.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/build/repo/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "sources-id",
    path: "/sources/:id()",
    component: () => import("/opt/build/repo/pages/sources/[id].vue").then(m => m.default || m)
  },
  {
    name: "sources-add-cloudtrail",
    path: "/sources/add/cloudtrail",
    component: () => import("/opt/build/repo/pages/sources/add/cloudtrail.vue").then(m => m.default || m)
  },
  {
    name: "sources-add-datadog_agent",
    path: "/sources/add/datadog_agent",
    component: () => import("/opt/build/repo/pages/sources/add/datadog_agent.vue").then(m => m.default || m)
  },
  {
    name: "sources-add-elastic",
    path: "/sources/add/elastic",
    component: () => import("/opt/build/repo/pages/sources/add/elastic.vue").then(m => m.default || m)
  },
  {
    name: "sources-add-hec",
    path: "/sources/add/hec",
    component: () => import("/opt/build/repo/pages/sources/add/hec.vue").then(m => m.default || m)
  },
  {
    name: "sources-add-http",
    path: "/sources/add/http",
    component: () => import("/opt/build/repo/pages/sources/add/http.vue").then(m => m.default || m)
  },
  {
    name: "sources-add",
    path: "/sources/add",
    component: () => import("/opt/build/repo/pages/sources/add/index.vue").then(m => m.default || m)
  },
  {
    name: "sources-add-netlify",
    path: "/sources/add/netlify",
    component: () => import("/opt/build/repo/pages/sources/add/netlify.vue").then(m => m.default || m)
  },
  {
    name: "sources-add-open_telemetry",
    path: "/sources/add/open_telemetry",
    component: () => import("/opt/build/repo/pages/sources/add/open_telemetry.vue").then(m => m.default || m)
  },
  {
    name: "sources-add-prometheus_remote_write",
    path: "/sources/add/prometheus_remote_write",
    component: () => import("/opt/build/repo/pages/sources/add/prometheus_remote_write.vue").then(m => m.default || m)
  },
  {
    name: "sources-add-vercel",
    path: "/sources/add/vercel",
    component: () => import("/opt/build/repo/pages/sources/add/vercel.vue").then(m => m.default || m)
  },
  {
    name: "sources",
    path: "/sources",
    component: () => import("/opt/build/repo/pages/sources/index.vue").then(m => m.default || m)
  },
  {
    name: "streams-id",
    path: "/streams/:id()",
    component: () => import("/opt/build/repo/pages/streams/[id].vue").then(m => m.default || m)
  },
  {
    name: "streams-add",
    path: "/streams/add",
    component: () => import("/opt/build/repo/pages/streams/add/index.vue").then(m => m.default || m)
  },
  {
    name: "streams",
    path: "/streams",
    component: () => import("/opt/build/repo/pages/streams/index.vue").then(m => m.default || m)
  }
]