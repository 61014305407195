import revive_payload_client_wIPltXJb3D from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__xoyqjxpk37qctwt7umftnzk6l4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JihEd0cbRf from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__xoyqjxpk37qctwt7umftnzk6l4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_nhBolTttnJ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__xoyqjxpk37qctwt7umftnzk6l4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_6dh4GH2LFO from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__xoyqjxpk37qctwt7umftnzk6l4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_W5jzoskub0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__xoyqjxpk37qctwt7umftnzk6l4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_V3MxBfenou from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__xoyqjxpk37qctwt7umftnzk6l4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_QQBG46MvAo from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__xoyqjxpk37qctwt7umftnzk6l4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_okYasLtkry from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.2_typescript@5.5.4_vue@3.5.1_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_tUi4ooYkds from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__xoyqjxpk37qctwt7umftnzk6l4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import notiwind_qYGIDwLcpw from "/opt/build/repo/plugins/notiwind.ts";
import sentry_client_shVUlIjFLk from "/opt/build/repo/plugins/sentry.client.ts";
import koala_client_WKDdOaEvL9 from "/opt/build/repo/plugins/koala.client.ts";
export default [
  revive_payload_client_wIPltXJb3D,
  unhead_JihEd0cbRf,
  router_nhBolTttnJ,
  payload_client_6dh4GH2LFO,
  navigation_repaint_client_W5jzoskub0,
  check_outdated_build_client_V3MxBfenou,
  chunk_reload_client_QQBG46MvAo,
  plugin_vue3_okYasLtkry,
  components_plugin_KR1HBZs4kY,
  prefetch_client_tUi4ooYkds,
  notiwind_qYGIDwLcpw,
  sentry_client_shVUlIjFLk,
  koala_client_WKDdOaEvL9
]